.propertyList {
    padding: 0;
}

.propertyListItem {
    display: flex;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 1.125;
}

.propertyListItem:last-child {
    margin-bottom: 0;
}

.propertyListItem__lbl {
    position: relative;
    margin-right: 8px;
    font-weight: 700;
}

.propertyListItem__lbl.withIcon {
    padding-left: 20px
}

.propertyListItem__icon {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
    fill: #808080;
}

.propertyListItem__val {}

.propertyListItem__val__size_lg {
    font-size: 16px;
}


.propertyList.compact {
    padding: 0;
    border: 0;
}

.propertyList.compact .propertyListItem {
    display: block;
}

.propertyList.compact .propertyListItem__lbl {
    margin-bottom: 2px;
}

.propertyList.compact .propertyListItem__val {
    line-height: 1.125;
}