.ant-btn {
  border-radius: 8px;
  border-color: #d2d7dd;
}

.ant-btn-sm {
  border-radius: 6px;
}

.ant-btn-lg {
  min-width: 36px;
  height: 37px;
  font-size: 14px;
}

.ant-btn-primary {
  color: #fff;
  border-color: var(--ant-primary-color);
  background: var(--ant-primary-color);
}

.ant-btn-danger {
  color: #fff;
  border-color: var(--ant-error-color);
  background: var(--ant-error-color);
}

.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #DBDCDB;
  border-radius: 2em 0 0 2em;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0 2em 2em 0;
}


.ant-radio-group-small label {
  font-size: 12px;
}


.ant-input-affix-wrapper-lg {
  padding: 5px 11px;
  font-size: 14px;
}

.ant-picker-large {
  padding: 5px 11px;
}


.ant-tabs-tab-btn,
.ant-tabs-tab-remove {
  outline: none;
  transition: all .1s;
}

.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav:before,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav:before {
  border-bottom: 1px solid #d2d7dd;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  display: block;
  height: 10px;
}

.tabsForm {
  margin-top: -33px;
}


.tabsForm .ant-tabs-nav {
  background: #fff;
  margin: 0;
}

.tabsForm .ant-tabs-tab-btn {
  font-weight: 600;
  color: #666F7C;
}

.tabsForm .ant-tabs-tab {
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1px solid transparent !important;
  background: transparent !important;
  border-radius: 8px 8px 0 0 !important;
}

.tabsForm .ant-tabs-tab:hover .ant-tabs-tab-btn {
  color: #0097EB !important;
}


.tabsForm .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0097EB;
}

.tabsForm.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.tabsForm.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
  background: #F3F2F2 !important;
  border: 1px solid #d2d7dd !important;
  border-bottom-color: #F3F2F2 !important;
}
