.tableList {
    position: relative;
}

.tableList .ant-table {
    background: transparent;
}

.tableList .ant-table table {
    border-collapse: separate;
    border-spacing: 0 5px;
}

.tableList .ant-table .ant-table-header,
.tableList .ant-table .ant-table-thead>tr>th {
    background: transparent;
    color: #56636f;
    font-size: 11px;
    text-transform: uppercase;
}

.tableList .ant-table .ant-table-thead>tr>th {
    border-bottom: 0;
    padding: 6px 12px;
}

.tableList .ant-table-thead .ant-table-cell {
    background: transparent !important;
    border: 0;
}

.tableList .ant-table .ant-table-tbody>tr>td:first-child {
    border-radius: 8px 0 0 8px;
    border-left: 1px solid #e8e8e8;
}

.tableList .ant-table .ant-table-tbody>tr>td:last-child {
    border-radius: 0 8px 8px 0;
    border-right: 1px solid #e8e8e8;
}

.tableList .ant-table .ant-table-tbody>tr>td {
    padding: 8px 12px;
    margin-bottom: 15px;
    color: #404346;
    background: #fff;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
}

.tableList .ant-table-tbody>tr:hover>td {
    background: #E8F4F9;
}
.tableList .ant-table-tbody>tr.ant-table-row:hover>td, 
.tableList .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: #E8F4F9;
}

.tableList .ant-table-tbody>tr>td:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #E7EBF6;
}

.tableList .ant-table-tbody>tr>td:last-child {
    border-radius: 0 4px 4px 0;
    border-right: 1px solid #E7EBF6;
}

.tableList .ant-table-scroll {
    overflow: auto;
    overflow-x: hidden;
}


.tableStat {
    position: absolute;
    bottom: 15px;
    left: 0;
    height: 24px;
    line-height: 24px;
    padding: 0 16px;
    color: #596570;
    font-size: 12px;
}

.tableList .ant-pagination-prev {
    min-width: 24px;
    margin-right: 6px;
    height: 24px;
    line-height: 24px;
}

.tableList .ant-pagination-next {
    min-width: 24px;
    height: 24px;
    line-height: 24px;
}

.tableList .ant-pagination-prev .ant-pagination-item-link,
.tableList .ant-pagination-next .ant-pagination-item-link {
    border: 0;
    font-size: 12px;
    height: 24px;
    border-radius: 6px;
}

.tableList .ant-pagination-item,
.tableList .ant-pagination-total-text {
    min-width: 24px;
    height: 24px;
    line-height: 22px;
    margin-right: 6px;
    font-size: 12px;
    border-radius: 6px;
}