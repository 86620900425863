.logo {
    position: relative;
    margin: 24px auto 36px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #77d4fc;
    border-radius: 42px;
}

.logo:before {
    content: '';
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
    border-radius: 42px;
    border: 1px dashed rgba(255, 255, 255, .5);
}

.logoImg {
    margin-left: 12px;
    width: 56px;
    object-fit: contain;
    border-radius: 42px;
}

.logoTitle {
    position: relative;
    width: 72px;
    left: -12px;
    top: 8px;
    font-size: 11px;
    line-height: .875;
}

.appSider.ant-layout-sider-collapsed .logo {}

.appSider.ant-layout-sider-collapsed .logoImg {
    width: 56px;
}