/*@import '~antd/dist/antd.css';*/
/*@import '~antd/dist/antd.variable.min.css';*/
@import 'antd/dist/reset.css';
@import "./antdReset.css";

:root {
  --ant-primary-color: #457b9d;
  --ant-primary-color-hover: #5aa1cc;
  --ant-primary-color-active: #386682;
  --ant-primary-color-outline: rgba(24, 144, 255, 0.2);
  --ant-primary-1: #d0ded7;
  --ant-primary-2: #b2eacf;
  --ant-primary-3: #aae0c6;
  --ant-primary-4: #a0d3ba;
  --ant-primary-5: #93c4ac;
  --ant-primary-6: #457b9d;
  --ant-primary-7: #457b9d;
  --ant-primary-color-deprecated-l-35: #cbe6ff;
  --ant-primary-color-deprecated-l-20: #7ec1ff;
  --ant-primary-color-deprecated-t-20: #46a6ff;
  --ant-primary-color-deprecated-t-50: #8cc8ff;
  --ant-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
  --ant-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
  --ant-primary-color-active-deprecated-d-02: #dcf4ff;
  --ant-success-color: #52c41a;
  --ant-success-color-hover: #73d13d;
  --ant-success-color-active: #389e0d;
  --ant-success-color-outline: rgba(82, 196, 26, 0.2);
  --ant-success-color-deprecated-bg: #f6ffed;
  --ant-success-color-deprecated-border: #b7eb8f;
  --ant-error-color: #e63946;
  --ant-error-color-hover: #f63c4c;
  --ant-error-color-active: #d83643;
  --ant-error-color-outline: rgba(255, 77, 79, 0.2);
  --ant-error-color-deprecated-bg: #fff2f0;
  --ant-error-color-deprecated-border: #ffccc7;
  --ant-warning-color: #faad14;
  --ant-warning-color-hover: #ffc53d;
  --ant-warning-color-active: #d48806;
  --ant-warning-color-outline: rgba(250, 173, 20, 0.2);
  --ant-warning-color-deprecated-bg: #fffbe6;
  --ant-warning-color-deprecated-border: #ffe58f;
  --ant-info-color: #a8dadc;
  --ant-info-color-deprecated-bg: #ace6e8;
  --ant-info-color-deprecated-border: #95c2c4;
}

body {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;

  font-weight: 400;
  line-height: 1.375;
  /*color: #414954;
      fill: #414954;*/
  color: #000;
  margin: 0;
  padding: 0;
  background: #636C78;
  user-select: none;

  /*text-rendering: optimizeLegibility;*/
}


.additionalDomains {
  font-size: 12px;
  color: #787878;
}

.additionalDomainsTitle {
  margin: 0 4px 2px 0;
  display: inline-block;
}

.additionalDomainsBody>span:after,
.additionalDomainsBody>a:after {
  content: ', ';
}

.additionalDomainsBody>span:last-child:after,
.additionalDomainsBody>a:last-child:after {
  content: '';
}