.actionButton .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    padding: 0 8px;
    height: 24px;
    border-radius: 4px;
    background: #f0f0f0;
}

.actionButton_isSingle {
    font-size: 24px;
    margin-left: 16px;
}

.actionButton .ant-btn:nth-child(2) {
    width: 28px;
}

.actionButton .ant-btn:nth-child(2) .ant-btn-icon>.anticon-down {
    font-size: 10px !important;
}

.actionButtons1 .ant-btn {
    margin-bottom: 4px;
    min-width: 20px;
    height: 20px;
    padding: 4px;

    font-size: 10px;
    line-height: 1;
    border-radius: 4px;
    background: #e5e5e5;
}

.actionButtons1 .ant-btn:last-child {
    margin-bottom: 0;
}