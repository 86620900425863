.smartFilterValues {
    display: flex;
    flex-flow: row;
    align-items: center;
    margin: -24px -24px 0;
    padding: 8px 24px;
    font-size: 12px;
    line-height: 1;

    color: #333;
    background: #FCF4DD;
    border-bottom: 1px solid #d2d7dd;
}

.fieldset .smartFilterValues {
    margin-top: -17px;
    border-bottom: 0;
}

.page__witTabs .smartFilterValues {
    margin-top: -14px;
}

.smartFilterValues__title {
    margin-right: 16px;
    font-weight: 600;
}

.smartFilterValues__content {
    display: flex;
    flex-flow: row wrap;
}

.filterValue {
    display: flex;
    align-items: center;
    margin-right: 6px;
    padding: 4px 0;
}

.filterValue__label {
    margin-right: 5px;
}

.filterValue__values {
    display: flex;
}

.filterValue__values .ant-tag {
    border-radius: 2em;
    padding: 4px 12px;
    background: #FAEDC7;
    border-color: #EEDDAB;
}
