.detailLink {
    color: #000;
}

.valueFlex {
    display: flex;
    align-items: center;
    line-height: 1.125;
}

.valueFlex .image,
.valueFlex .avatar {
    margin-right: 8px;
}