.photoUpload {
    position: relative;
    display: block;
}

.photoUpload--full {
    /*width: 100%;
    height: 0;
    padding-bottom: 100%;*/
}

.photoUpload .ant-upload-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}
.photoUpload.col2 .ant-upload-list {
    grid-template-columns: repeat(2, 1fr);
}
.photoUpload.col3 .ant-upload-list {
    grid-template-columns: repeat(3, 1fr);
}
.photoUpload.col4 .ant-upload-list {
    grid-template-columns: repeat(4, 1fr);
}
.photoUpload.col5 .ant-upload-list {
    grid-template-columns: repeat(5, 1fr);
}

.photoUpload .ant-upload-list:before {
    display: none;
}

.photoUpload .ant-upload-list-picture-card-container {
    width: 100%;
    height: auto;
}

.photoUpload .ant-upload-draggable-list-item {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
}

.photoUpload .ant-upload-list-item-list-type-picture-card {
    position: absolute !important;
    width: 100%;
    height: 100%;
    border-radius: 8px !important;
    padding: 4px !important;
}

.photoUpload .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.photoUpload .ant-upload-select {
    padding: 8px;
}

.photoUpload .ant-upload-list-picture-card-container,
.photoUpload .ant-upload-list-picture-card .ant-upload-list-item,
.photoUpload .ant-upload.ant-upload-select-picture-card {
    /*width: 176px;
    height: 176px;*/
}


.ant-form-item-control.has-success .photoUpload .ant-upload.ant-upload-select-picture-card {
    height: 100%;
    margin: 0;
}

.photoUpload-btn-icon {
    font-size: 24px;
    color: #5F6B78;
}

.photoUpload-btn-text {
    margin-top: 2px;
    font-size: 12px;
    line-height: 1.125;
    color: #5F6B78;
}

.ant-form-item-control.has-success .photoUpload-btn {
    display: flex;
    align-items: center;
}

.ant-form-item-control.has-success .photoUpload-btn-icon {
    margin-right: 8px;
    font-size: 20px;
}

.ant-form-item-control.has-success .photoUpload-btn-text {
    margin-top: 0;
    font-size: 16px;
    line-height: 1;
}