.navigation.ant-menu {
    padding-bottom: 36px;
    border-right: 0 !important;
    user-select: none;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.75);
    background: #636C78 !important;
}

.navigation .ant-menu-item-group {
    padding: 0 22px;
}

.navigation .ant-menu-item-group-title {
    text-transform: uppercase;
    letter-spacing: .01em;
    font-size: 10px;
    line-height: 16px;
    padding: 6px 0 0;
    color: #2c3036;
    color: #9BA1A8;
}

.navigation .ant-menu-item-divider {
    margin: 8px 0;
    border-color: rgba(5, 5, 5, 0.16);
}

.navigation .ant-menu-item {
    padding: 0 18px !important;
    height: 32px !important;
    line-height: 36px !important;
}

.navigation .ant-menu-submenu-title {
    padding: 0 18px !important;
}

.navigation .ant-menu-item-only-child1 {
    padding: 0 18px 0 40px !important;
}

.navigation .ant-menu-item-icon {
    position: relative;
    z-index: 2;
}

.navigation .ant-menu-title-content {
    position: relative;
    z-index: 2;
}


.navigation .ant-menu-sub.ant-menu-inline {
    background: #636C78 !important;
}

.navigation .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    padding: 0 18px 0 43px !important;
}


.navigation .ant-menu-item:active,
.navigation .ant-menu-submenu-title:active {
    background: transparent
}


.navigation .ant-menu-item-selected {
    color: #fff !important;
    background-color: transparent !important;
}

.navigation .ant-menu-item-selected:before {
    content: '';
    z-index: 0;
    position: absolute;
    left: 4px;
    right: 4px;
    top: 0;
    bottom: 0;
    border-radius: 2em;
    background: rgba(0, 0, 0, .25);
}


.appSider.ant-layout-sider-collapsed .navigation .ant-menu-item {
    padding: 0 26px !important;
}

.appSider.ant-layout-sider-collapsed .navigation .ant-menu-item-group {
    padding: 0 8px;
    text-align: center;
}
.appSider.ant-layout-sider-collapsed .navigation .ant-menu-item-group-title {
    font-size: 8px;
}
